<template>
  <div class="news-detail">
    <div class="category pc" v-if="data?.category">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <router-link class="before" :to="'/news'">Tin Tức</router-link>
      <span class="tag-mid">/</span>
      <router-link class="before" :to="'/news/' + data?.category?.slug"
        >{{ data?.category[locale]?.name }}
      </router-link>
      <span class="tag-mid">/</span>
      <router-link
        class="after"
        :to="
          '/news/' +
          data?.category?.slug +
          '/' +
          data?.detail?.slug +
          '?id=' +
          data?.detail?.id
        "
      >
        {{ data?.detail[locale]?.title }}
      </router-link>
    </div>
    <div class="detail-main" v-if="data?.detail">
      <div class="detail-main__bg">
        <img :src="data.detail?.image" alt="" />
      </div>
      <div class="detail-main__content">
        <h1 class="title">
          {{ data.detail[locale].title }}
        </h1>
        <p class="time">{{ getDay(data.detail?.created_at) }}</p>
        <h3 class="intro">
          {{ data.detail[locale].description }}
        </h3>
        <div class="content" v-html="data.detail[locale].content"></div>
      </div>
    </div>
    <div class="news-other">
      <h1 class="news-other__title">Các Tin Khác</h1>
      <div class="news-other__content" v-if="data.other_news">
        <div class="item" v-for="(item, index) in data.other_news" :key="index">
          <router-link
            :to="
              '/news/' + data.category.slug + '/' + item.slug + '?id=' + item.id
            "
          >
            <div class="item__bg">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-content">
              <h5 class="item__category">{{ data.category[locale].name }}</h5>
              <h1 class="item__title">
                {{ item[locale].title }}
              </h1>
              <h1 class="item__time">{{ getDay(item.created_at) }}</h1>
              <p class="item__des">
                {{ item[locale].description }}
              </p>
              <div class="item__view">
                <a href="#">
                  <img
                    src="../assets/img/icons/arrow-right-orange.svg"
                    alt=""
                  />
                  <span>XEM TIẾP</span>
                </a>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <router-link v-if="data.category" :to="data.category.link">
        <div class="view-more">Xem Thêm</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsService from "@/services/NewsService";
export default {
  data() {
    return {
      category: "Tin Tức",
      title: "Những Câu CHuyện Mới Nhất Trong Làng Golf",
      data: "",
    };
  },
  mounted() {
    this.scroll();
    this.detailNews();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    "$route.path"() {
      this.detailNews();
      this.scroll();
    },
  },
  methods: {
    async detailNews() {
      await NewsService.getdetailNew(this.$route.query.id)
        .then((resp) => {
          this.data = resp.data.data;
          this.$forceUpdate();
        })
        .catch(() => {});
    },
    getDay(day) {
      const arr = day.split("-");
      return "Ngày " + arr[0] + " tháng " + arr[1] + " năm " + arr[2];
    },
    scroll() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
